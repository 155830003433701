import React, { ChangeEvent } from "react";
// Customizable Area Start
import { Typography, Box, Select, MenuItem, Modal, Tooltip } from "@mui/material";
import { Formik } from "formik";
import { styled } from '@mui/material/styles';
import Dropzone from 'react-dropzone';
import moment from "moment";
import * as Yup from "yup";

import {
  companyNameIcon,
  numberIcon,
  managerIcon,
  contactInfoIcon,
  rightArrowIcon,
  leftArrowIcon,
  closeIcon,
  successIcon,
  uploadIcon,
  addIcon,
  removeIcon,
  errorIcon,
} from "./assets";
// Customizable Area End

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

import AddReadingPageController, { configJSON, Props } from "./AddReadingPageController";
// Customizable Area Start
// Customizable Area End

const acceprtFileType = ["image/png", "image/jpeg"]
export default class AddReadingPage extends AddReadingPageController {
  constructor(props: Props) {
    super(props);
  }
  
  createReadingSchema = () => {
    return Yup.object().shape({
      currReading: Yup.string()
        .required("Current reading is required")
        .matches(/^[0-9]+$/, "Must be only digits")
        .max(10, 'Must be 10 digits maximum'),
      note: Yup.string()
        .required("Meter reading note is required"),
    });
  };

  selectProperty = () => (
    <Box sx={webStyle.signUpFormContainer}>
      <Box sx={webStyle.addReadingTitle}>
        <Typography style={webStyle.formTitle}>{configJSON.step1TitleText}</Typography>
        <Typography style={webStyle.formSubTitle}>
          {configJSON.step1SubTitleText}
        </Typography>
      </Box>
      <Formik
        initialValues={{
          bookingId: -1,
          property: -1,
          meterNumber: -1,
        }}
        onSubmit={() => {}}
        data-test-id="AddRadingStepOne"
      >
        {({ setFieldValue, values }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.fieldControl}>
                <label
                  style={webStyle.inputLabel}
                  htmlFor={"meterNumber"}
                >
                  {configJSON.bookFieldLabel}
                </label>
                <Box sx={webStyle.bookingField}>
                  <Select
                    value={values?.bookingId}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="bookingId"
                    style={
                      values?.bookingId === -1
                        ? { ...webStyle.selectOptionStart, width: "50%" }
                        : { ...webStyle.selectOption(), width: "50%"}
                    }
                    onChange={(event) => {
                      setFieldValue("bookingId", event.target.value);
                      setFieldValue("property", -1);
                      this.setSelectedProperties(
                        Number(event.target.value)
                      );
                    }}
                  >
                    {values?.bookingId === -1 && (
                      <MenuItem
                        value={-1}
                        style={{...webStyle.selectOptionStart, display: "none"}}
                      >
                        {configJSON.bookFieldPlaceholder}
                      </MenuItem>
                    )}
                    {new Array(10).fill(0).map((_, index) => (
                      <MenuItem 
                        key={index}
                        value={index + 1} 
                        style={webStyle.selectOption(values.bookingId === (index + 1))}
                      >
                          {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box sx={webStyle.removeBookingContaineer}>
                    {values?.bookingId > 0 && (
                      <Box sx={webStyle.removeBookingContainer}>
                        <Box 
                          sx={webStyle.removeBookingButton}
                          onClick={() => {
                            setFieldValue("bookingId", -1);
                            setFieldValue("property", -1);
                            this.setState({ selectedReading: null, selectedProperty: null });
                          }}
                        >
                          <img 
                            src={closeIcon}
                            style={webStyle.reemoveBookingIcon} 
                          />
                          <Typography sx={webStyle.removeBookingText}>{configJSON.clearText}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={webStyle.fieldControl}>
                <label style={webStyle.inputLabel} htmlFor={"property"}>
                  {configJSON.propertyFieldLabel}
                </label>
                <Select
                  value={values?.property}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  data-test-id="property"
                  style={
                    values?.property === -1
                      ? webStyle.selectOptionStart
                      : webStyle.selectOption()
                  }
                  onChange={(event) => {
                    setFieldValue("property", event.target.value);
                    this.setSelectedReading(Number(event.target.value));
                  }}
                >
                  {values?.property === -1 && (
                    <MenuItem
                      key={-1}
                      value={-1}
                      style={{...webStyle.selectOptionStart, display: "none"}}
                    >
                      {configJSON.propertyFieldPlaceholder}
                    </MenuItem>
                  )}
                  {this.state.properies.map((p) => (
                    <MenuItem
                      key={p.id}
                      value={p.id}
                      style={webStyle.selectOption(values.property === p.id)}
                    >
                      {p.property_name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box sx={webStyle.fieldControl}>
                <Box sx={webStyle.informationBlock}>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img
                        src={companyNameIcon}
                        style={webStyle.infoIcon}
                      />
                      <Typography style={webStyle.informationTitle}>
                        {configJSON.companyNameText}
                      </Typography>
                    </Box>
                    <Typography style={webStyle.informationField}>
                      {this.state.selectedReading?.company_name || "-"}
                    </Typography>
                  </Box>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img
                        src={contactInfoIcon}
                        style={webStyle.infoIcon}
                      />
                      <Typography style={webStyle.informationTitle}>
                        {configJSON.contactInfoText}
                      </Typography>
                    </Box>
                    {(this.state.selectedReading?.customer || this.state.selectedReading?.phone_number) ? (
                      <>
                        <Typography style={webStyle.informationField}>
                          {this.state.selectedReading?.customer}
                        </Typography>
                        <Typography style={webStyle.informationField}>
                          {this.state.selectedReading?.phone_number}
                        </Typography>
                      </>
                    ) : "-"}
                  </Box>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img
                        src={managerIcon}
                        style={webStyle.infoIcon}
                      />
                      <Typography style={webStyle.informationTitle}>
                        {configJSON.managerText}
                      </Typography>
                    </Box>
                    <Typography style={webStyle.informationField}>
                      {this.state.selectedReading?.building_manager || "-"}
                    </Typography>
                  </Box>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img src={numberIcon} style={webStyle.infoIcon} />
                      <Typography style={webStyle.informationTitle}>
                        {configJSON.officeNumberText}
                      </Typography>
                    </Box>
                    <Typography style={webStyle.informationField}>
                      {this.state.selectedReading?.office_number || "-"}
                    </Typography>
                  </Box>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img src={numberIcon} style={webStyle.infoIcon} />
                      <Typography style={webStyle.informationTitle}>
                        {configJSON.superNumberText}
                      </Typography>
                    </Box>
                    <Typography style={webStyle.informationField}>
                      {this.state.selectedReading?.super_number || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                <button
                  style={webStyle.submitButton(!this.state.selectedProperty)}
                  data-test-id="nextBtn"
                  type="button"
                  // disabled={!this.state.selectedProperty}
                  onClick={() => {
                    this.state.selectedProperty && this.setState({ step: 2 });
                  }}
                >
                  <Typography sx={webStyle.buttonText}>{configJSON.nextButtonText}</Typography>
                  <img src={rightArrowIcon} style={webStyle.infoIcon} />
                </button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )

  selectMeter = () => (
    <Box sx={webStyle.meterListTableContainer}>
      {this.state.premiseMeterList.length ? (
        <Modal
          open={!!this.state.modalWindowId}
          onClose={() => {
            this.setModalWindowId(null);
          }}
          data-test-id={"meterNoteModal"}
        >
          <Box sx={webStyle.modalBox}>
            <Box sx={webStyle.modalTitleBlock}>
              <Typography style={webStyle.modalTitle}>
                {"Meter #  "}
                {this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.meter_number}
                {" - "}
                {this.state.tenants.find(({ id }) => id === this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.tenant_id)?.tenant_name}
              </Typography>
              <button
                style={webStyle.modalCloseButton}
                onClick={() => this.setModalWindowId(null)}
              >
                <img src={closeIcon} alt="Logo" style={{ height: "24px" }} />
              </button>
            </Box>
            <Box component="div" sx={webStyle.modalScrollBox}>
              <Box sx={webStyle.modalBlock}>
                <Typography sx={webStyle.modalBlockLabel}>
                  {configJSON.meterNotesText}
                </Typography>
                <Typography sx={webStyle.modalBlockValue}>
                  {this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.meter_note}
                </Typography>
              </Box>
              <Box sx={webStyle.modalCloseButtonBlock}>
                <button
                  style={webStyle.closeButton}
                  data-test-id="submitBtn"
                  type="button"
                  onClick={() => this.setModalWindowId(null)}
                >
                  <Typography sx={webStyle.buttonText}>{configJSON.closeButtonText}</Typography>
                </button>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : ('')}
      <Box sx={webStyle.addReadingTitle}>
        <Typography style={webStyle.formTitle}>{configJSON.step2TitleText}</Typography>
        <Typography style={webStyle.formSubTitle}>
          {configJSON.step2SubTitleText}
        </Typography>
      </Box>
      <Box style={{ minHeight: 1 > 1 ? "674px" : "unset" }} sx={webStyle.tableWrapper(1)}>
        <Box sx={webStyle.metersTable}>
          <Box sx={webStyle.metersTableHead}>
            <Typography style={webStyle.headerMeterTitle}>
              {configJSON.meterNumberText}
            </Typography>
            <Typography style={webStyle.headerTenantTitle}>
              {configJSON.tenantText}
            </Typography>
            <Typography style={webStyle.headerMeterNoteTitle}>
              {configJSON.meterNoteText}
            </Typography>
            <Typography style={webStyle.headerLastRecordTitle}>
              {configJSON.lastRecordText}
            </Typography>
          </Box>
          {this.state.premiseMeterList.map((meter, index) => (
            <Box sx={webStyle.metersTableBody} key={index} onClick={() => this.setCurrentMeter(meter.id)}>
              <Box sx={webStyle.propertyMeterItem}>{meter.meter_number} 
                {this.state.currentMeter === meter.id && (<img src={successIcon} style={webStyle.successIcon}/>)}
              </Box>
              <Box sx={webStyle.propertyTenantItem}>
                {this.state.tenants.find(({ id }) => id === meter.tenant_id)?.tenant_name}
              </Box>
              <Box sx={webStyle.propertyMeterNoteItem}>
                <Typography sx={webStyle.meterNote}>{meter.meter_note || " - "}</Typography>
                {meter.meter_note && (
                  <Typography 
                    sx={webStyle.readMore} 
                    data-test-id={`readMore.${meter.id}`} 
                    onClick={() => this.setModalWindowId(meter.id)}
                  >
                    {configJSON.readMoreText}
                  </Typography>
                )}
              </Box>
              <Box sx={webStyle.propertyLastRecordItem}>{moment(meter.date_of_last_reading).format('l LT')}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box sx={webStyle.fieldControlSecontStepGroup}>
        <button
          style={webStyle.prevButton}
          data-test-id="prevBtn"
          type="button"
          onClick={() => {
            this.setState({ step: 1 });
          }}
        >
          <img src={leftArrowIcon} style={webStyle.infoIcon} />
          <Typography sx={webStyle.buttonText}>{configJSON.prevButtonText}</Typography>
        </button>
        <button
          style={webStyle.submitButton(!this.state.currentMeter)}
          data-test-id="nextBtn"
          type="button"
          onClick={() => {
            this.state.currentMeter && this.setState({ step:3 });
          }}
        >
          <Typography sx={webStyle.buttonText}>
            {configJSON.nextButtonText}
          </Typography>
          <img src={rightArrowIcon} style={webStyle.infoIcon} />
        </button>
      </Box>
    </Box>
  )

  submitReading = () => { 
    const currentPremiseMeter = this.state.premiseMeterList.find((pm) => pm.id == this.state.currentMeter);
    const last_reading = Number(currentPremiseMeter?.last_reading);
    const date_of_last_reading = moment(currentPremiseMeter?.date_of_last_reading).format('l LT');
 
    return (
      <Box sx={webStyle.signUpFormContainer}>
        <Box sx={webStyle.addReadingTitle}>
          <Typography style={webStyle.formTitle}>
            {"Meter #  "}
            {this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.meter_number}
            {" - "}
            {this.state.tenants.find(({ id }) => id === this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.tenant_id)?.tenant_name}
          </Typography>
          <Typography style={webStyle.formSubTitle}>
            {configJSON.step3SubTitleText}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            premise: null,
            meterNumber: null,
            currReading: "",
            consumption: "",
            note: "",
            files: [],
          }}
          validateOnMount={true}
          validateOnChange={true}
          validationSchema={this.createReadingSchema}
          onSubmit={() => {
            const { id: meter_id = 0, tenant_id = 0, property_id = 0 } = this.state.premiseMeterList
              .find((pm) => pm.id == this.state.currentMeter) || {};

            this.addMeterReadingData(property_id, tenant_id, meter_id);
          }}
          data-test-id="AddRadingStepTree"
        >
          {({ values, errors, setFieldValue, handleSubmit }) => (
            <form>
              <Box sx={webStyle.formContainer}>
                <Box sx={webStyle.fieldControl}>
                  <Box sx={webStyle.prevTitle}>
                    <Box sx={webStyle.prevEmptyTitle}>
                      <label
                        style={webStyle.inputLabel}
                        htmlFor={"prevReading"}
                      >
                        {configJSON.previousReadingText}
                      </label>
                      {!last_reading ? (
                        <label
                          style={{...webStyle.aditionInputLabel, marginLeft: '4px'}}
                          htmlFor={"prevReading"}
                        >
                          {configJSON.notAvaliableText}
                        </label>
                      ) : ("")}
                    </Box>
                    {(last_reading && date_of_last_reading) ? (
                      <label
                        style={webStyle.aditionInputLabel}
                        htmlFor={"prevReading"}
                      >
                        {date_of_last_reading}
                      </label>
                    ) : ("")}
                  </Box>
                  {last_reading ? (
                    <input
                      style={webStyle.inputDisabledElement}
                      data-test-id="prevReading"
                      type="text"
                      value={last_reading}
                      disabled
                    />
                  ) : ("")}
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <label
                    style={webStyle.inputLabel}
                    htmlFor={"currReading"}
                  >
                    {configJSON.currentReadingText}
                  </label>
                  <input
                    style={webStyle.inputElement}
                    data-test-id="currReading"
                    type="text"
                    value={values?.currReading}
                    onChange={(event) => {
                      const currentReading = Number(event.target.value);
                      setFieldValue(
                        "consumption",
                        currentReading - last_reading
                      );
                      setFieldValue("currReading", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.currReading && (
                    <Tooltip
                      open={Boolean(errors.currReading)}
                      title={errors.currReading}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img
                        src={errorIcon}
                        alt=""
                        style={webStyle.errorIcon}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <label
                    style={webStyle.inputLabel}
                    htmlFor={"consumption"}
                  >
                    {configJSON.consumptionText}
                  </label>
                  <input
                    style={webStyle.inputDisabledElement}
                    data-test-id="consumption"
                    type="text"
                    disabled
                    value={values?.consumption || (Number(values?.currReading) - last_reading)}
                  />
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <label style={webStyle.inputLabel} htmlFor={"note"}>
                    {configJSON.meterReadingNoteText}
                  </label>
                  <textarea
                    style={
                      this.state.noteError
                        ? webStyle.textAreaErrorElement
                        : webStyle.textAreaElement
                    }
                    data-test-id="note"
                    value={values?.note}
                    onChange={(event) => {
                      setFieldValue("note", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.note && (
                    <Tooltip
                      open={Boolean(errors.note)}
                      title={errors.note}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img
                        src={errorIcon}
                        alt=""
                        style={webStyle.errorIcon}
                      />
                    </Tooltip>
                  )}
                  <Typography
                    style={
                      this.state.noteError
                        ? webStyle.textAreaErrorComent
                        : webStyle.textAreaComent
                    }
                  >
                    {configJSON.enterNoteIfText}
                  </Typography>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  {values.files.length ? (
                    <Box sx={webStyle.fileListContainer}>
                      <Box sx={webStyle.fileListBlock}>
                        {values.files.map((file, index) => { 
                          var tmppath = URL.createObjectURL(file);
                          return (
                            <Box sx={webStyle.imageBlock} key={index}>
                              <Box 
                                sx={webStyle.removeeImageBlock} 
                                data-test-id={`removeImage.${index}`}
                                onClick={() => {
                                  const fileList = values.files;
                                  const filteredFileList = fileList.filter((_, idx) => idx !== index);
                                  setFieldValue("files", filteredFileList)
                                  this.setFileError("");
                                }}
                              >
                                <img src={removeIcon} style={webStyle.removeImage}/>
                              </Box>
                              <img src={tmppath} style={webStyle.uploadedImage}/>
                            </Box>
                          )}
                        )}
                        <Box sx={webStyle.addImageButton} component="label">
                          <VisuallyHiddenInput
                            type="file"
                            data-test-id="uploadZone"
                            accept={acceprtFileType.join(', ')}
                            onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                              const fileList = Array.from((event.target as HTMLInputElement).files as FileList);
                              const maxSizeError = fileList.some(f => f.size > 5 * 1024 * 1024);
                              const acceptedTypeError = fileList.some(f => !acceprtFileType.includes(f.type));
                              if (maxSizeError) {
                                this.setFileError(configJSON.tooBigFile);
                              } else if (acceptedTypeError) {
                                this.setFileError(configJSON.unAccepteedTypeFile);
                              } else {
                                this.setFileError("");
                                setFieldValue("files", [...values.files, ...fileList]);
                              }
                            }}
                          />
                          <img src={addIcon} style={webStyle.infoIcon} />
                          <Typography sx={webStyle.addImagesText}>{configJSON.addImageText}</Typography>
                        </Box>
                      </Box>
                      <Typography 
                        hidden={!this.state.fileError}
                        style={webStyle.tobigFileText}
                      >
                        {this.state.fileError}
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <Dropzone onDrop={([ file ]) => {
                        const maxSizeError = file.size > 5 * 1024 * 1024;
                        const acceptedTypeError = !acceprtFileType.includes(file.type);

                        if (maxSizeError) {
                          this.setFileError(configJSON.tooBigFile);
                        } else if (acceptedTypeError) {
                          this.setFileError(configJSON.unAccepteedTypeFile);
                        } else {
                          this.setFileError("");
                          setFieldValue("files", [...values.files, file]);
                        }
                      }}>
                        {({getRootProps, getInputProps}) => (
                          <Box 
                            sx={webStyle.uploadFile(!!this.state.fileError)} 
                            component="label"
                            {...getRootProps()}
                          >
                            <VisuallyHiddenInput
                              {...getInputProps()}
                              type="file"
                              accept={acceprtFileType.join(', ')}
                              data-test-id="uploadZone"
                              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                const fileList = Array.from((event.target as HTMLInputElement).files as FileList);
                                const maxSizeError = fileList.some(f => f.size > 5 * 1024 * 1024);
                                const acceptedTypeError = fileList.some(f => !acceprtFileType.includes(f.type));
                                if (maxSizeError) {
                                  this.setFileError(configJSON.tooBigFile);
                                } else if (acceptedTypeError) {
                                  this.setFileError(configJSON.unAccepteedTypeFile);
                                } else {
                                  this.setFileError("");
                                  setFieldValue("files", [...values.files, ...fileList]);
                                }
                              }}
                              multiple
                            />
                            <img src={uploadIcon} style={webStyle.uploadIcon} />
                            <Box sx={webStyle.uploadFileText}>
                              <Typography sx={webStyle.uploadFileClickText}>
                                {configJSON.clickToUploadText}
                              </Typography>
                              <Typography sx={webStyle.uploadFiledragAndDropText}>
                                {" "}{configJSON.dragAndDropText}
                              </Typography>
                            </Box>
                            <Typography sx={webStyle.uploadFileTypesText}>
                              {configJSON.imageTypeText}
                            </Typography>
                          </Box>
                        )}
                      </Dropzone>
                      <Typography 
                        hidden={!this.state.fileError}
                        style={webStyle.tobigFileText}
                      >
                        {this.state.fileError}
                      </Typography>
                    </>
                  )}
                </Box>
                <Box sx={webStyle.fieldControlSecontStepGroup}>
                  <button
                    style={webStyle.prevButton}
                    data-test-id="prevBtn"
                    type="button"
                    onClick={() => {
                      this.setState({ step: 2 });
                    }}
                  >
                    <img src={leftArrowIcon} style={webStyle.infoIcon} />
                    <Typography sx={webStyle.buttonText}>{configJSON.prevButtonText}</Typography>
                  </button>
                  <button
                    style={webStyle.submitButton()}
                    data-test-id="submitBtn"
                    type="button"
                    onClick={() => {
                      this.setFormDate(values);
                      this.setValidate(true);
                      if (Object.keys(errors).length == 0 && !this.state.fileError) {
                        handleSubmit();
                      }
                    }}
                  >
                    <Typography sx={webStyle.buttonText}>
                      {configJSON.submitButtonTeext}
                    </Typography>
                  </button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    )
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box role="tabpanel" hidden={this.state.step !== 1}>
            { this.selectProperty() }
          </Box>
          <Box role="tabpanel" hidden={this.state.step !== 2}>
            { this.selectMeter() }
          </Box>
          <Box role="tabpanel" hidden={this.state.step !== 3}>
            { this.submitReading() }
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start

const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    margin: 'auto 0',
    height: '100%',
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  meterListTableContainer: {
    margin: "auto",
    maxWidth: "1000px",
    width: "80%",
  },
  addReadingTitle: {
    margin: "20px 10px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: "8px",
    color: '#0F172A',
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    marginBottom: "20px",
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  prevTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  prevEmptyTitle: {
    display: "flex",
    flexDirection: "row",
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldControlSecontStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  aditionInputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
  },
  inputDisabledElement: {
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#F1F5F9",
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  textAreaErrorElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #F87171",
  },
  textAreaComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    marginTop: "5px",
    marginBottom: "5px",
  },
  textAreaErrorComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#DC2626",
    marginTop: "5px",
    marginBottom: "5px",
  },
  selectOptionStart: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#94A3B8",
    backgroundColor: '#FFFFFF',
    flex: "auto",
  },
  selectOption: (selected: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: selected ? 600 : 400,
    lineHeight: "24px",
    color: selected ? "#1A7BA4" : "#0F172A",
    backgroundColor: selected ? "#E8FBFF" : '#FFFFFF',
    flex: "auto",
    borderRadius: "8px",
  }),
  prevButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "30px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#F8FAFC",
    color: "#1A7BA4",
    padding: "0px 16px 0px 8px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: (disabled: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    opacity: disabled ? 0.6 : 1,
    cursor: disabled ? "not-allowed" : "pointer"
  }),
  closeButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "16px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  addImageButton: {
    margin: "27px 0px 27px 16px;",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#ECF3F7",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  },
  informationBlock: {
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
  },
  informationFieldBlock: {
    margin: "18px 6px",
  },
  informationTitle: {
    color: "#94A3B8",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    alignItems: "center",
    display: "flex",
    margin: "0px 5px",
  },
  informationField: {
    color: "#0F172A",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "6px 0px",
  },
  informationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0px",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
  successIcon: {
    width: "20px",
    height: "20px",
    marginLeft: "5px",
  },
  reemoveBookingIcon: {
    width: "20px",
    height: "20px",
    padding: "6px 0px",
  },
  bookingField: {
    display: "flex",
    flexDirection: "row",
  },
  removeBookingContainer: {
    display: "flex",
    flexDirection: "row",
  },
  removeBookingButton: {
    display: "flex",
    margin: "13px 19px",
    flexDirection: "row",
    cursor: "pointer",
  },
  removeBookingContaineer: {
    flex: "auto",
    width: "50%",
  },
  removeBookingText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",

    alignItems: "center",
    display: "flex",
    color: "#475569",
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? '674px' : 'unset',
    marginBottom: "30px",
  }),
  metersTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
  },
  metersTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: 'rgba(248, 250, 252, 1)',
    borderRadius: '12px',
  },
  metersTableDataHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 340px)",
  },
  metersTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    '&:last-of-type': {
      borderRadius: '0 0 12px 12px',
    }
  },
  metersTableDataBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 340px)",
  },
  headerMeterTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTenantTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerMeterNoteTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "40%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerLastRecordTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  propertyMeterItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
  },
  propertyTenantItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  propertyMeterNoteItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "40%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexDirection: "row",
  },
  propertyLastRecordItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  meterNote: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(100% - 100px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  readMore: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#2563EB",
    width: "100px",
    cursor: "pointer",
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "auto",
    maxHeight: "80vh",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
  },
  modalScrollBox: {
    marginBottom: "75px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    flex: 1,
    color: "0F172A",
  },
  modalTitleBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
    marginTop: "-12px",
    marginBottom: "20px",
    borderBottom: "1px solid #CBD5E1",
  },
  modalTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
  },
  modalCloseButton: {
    color: "#64748B",
    border: "none",
    backgroundColor: "#F8FAFC",
    borderRadius: '100%',
    width: '56px',
    height: '56px',
    cursor: 'pointer',
  },
  modalDoubleBlock: {
    display: "flex",
    flexDirection: "row",
  },
  modalBlock: {
    display: "flex",
    margin: "8px 0px",
    flexDirection: "column",
  },
  modalCloseButtonBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "16px 0px 32px",
    borderTop: "1px solid #CBD5E1",
    bottom: "0px",
    position: "absolute",
    width: "calc(100% - 64px)",
  },
  modalBlockLabel: {
    margin: "0px 0px 10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#334155",
  },
  modalBlockValue: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    margin: "0px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "15px 10px",
    maxHeight: "280px",
    overflowX: "auto",
  },
  uploadIcon: {
    width: "32px",
    height: "32px",
    margin: "auto",
    display: "flex",
  },
  uploadFile: (error: boolean = false) => ({
    background: "#1A7BA40D",
    border: error ? "1px dashed #F87171" : "1px dashed #1A7BA4",
    width: "100%",
    padding: "15px 0px 20px",
    borderRadius: "14px",
    cursor: "pointr",
  }),
  uploadFileText: {
    display: "flex",
    flexDirection: "row",
    margin: "8px auto",
    justifyContent: "center",
  },
  uploadFileClickText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "12px",
    color: "#64748B",
    marginRight: "6px",
  },
  uploadFiledragAndDropText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#475569",
  },
  uploadFileTypesText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#475569",
    justifyContent: "center",
    display: "flex",
  },
  addImagesText: {
    margin: "0px 5px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    justifyContent: "center",
    display: "flex",
  },
  removeImage: {
    width: "10px", 
    height: "10px", 
    stroke: "#DC2626"
  },
  uploadedImage: { 
    width: "80px", 
    height: "80px", 
    borderRadius: "8px" 
  },
  removeeImageBlock: { 
    position: "absolute",
    left: "70px",
    top: "0px",
    backgroundColor: "#FEE2E2",
    borderRadius: "10px",
    padding: "5px",
  },
  imageBlock: { 
    width: "80px", 
    height: "80px", 
    position: "relative", 
    padding: "10px 20px 0px 0px" ,
    "&:nth-child(6n)": {
      padding: "10px 0px 0px 0px" ,
    },
  },
  tobigFileText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#DC2626",
  },
  fileListContainer: {
    display: "flex", 
    flexDirection: "column",
  },
  fileListBlock: {
    flexWrap: "wrap",
    display: "flex", 
    flexDirection: "row",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
};
// Customizable Area End
